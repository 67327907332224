export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
  Long: number;
};

export type AcceptTermsAndCondition = {
  __typename?: 'AcceptTermsAndCondition';
  acceptedAt: Scalars['AWSDateTime'];
  customerId: Scalars['ID'];
  version: Scalars['String'];
};

export type AssignedMachineInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AssignedMachineType = {
  __typename?: 'AssignedMachineType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum AsyncJobStatus {
  Created = 'CREATED',
  Done = 'DONE',
  Processing = 'PROCESSING'
}

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentNameCheck = {
  __typename?: 'AttachmentNameCheck';
  exists: Scalars['Boolean'];
};

export type AttachmentUploadLinkData = {
  __typename?: 'AttachmentUploadLinkData';
  fields: Array<Field>;
  url: Scalars['String'];
};

export enum BatteryDischargeStatus {
  Critical = 'CRITICAL',
  Ok = 'OK',
  Warning = 'WARNING'
}

export type CleanedAreaSummary = {
  __typename?: 'CleanedAreaSummary';
  periodCleanedArea: Scalars['Int'];
  totalCleanedArea: Scalars['Int'];
};

export type CleanedHrsSummary = {
  __typename?: 'CleanedHrsSummary';
  periodCleanedHrs: Scalars['Float'];
  totalCleanedHrs: Scalars['Float'];
};

export type CleaningReportSubscription = {
  __typename?: 'CleaningReportSubscription';
  formats: Array<CleaningReportSubscriptionReportFormat>;
  interval: CleaningReportSubscriptionInterval;
  type: CleaningReportSubscriptionType;
};

export enum CleaningReportSubscriptionInterval {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum CleaningReportSubscriptionReportFormat {
  Excel = 'EXCEL',
  Pdf = 'PDF'
}

export enum CleaningReportSubscriptionType {
  Events = 'EVENTS',
  Robot = 'ROBOT',
  Total = 'TOTAL'
}

export type CleaningReportsExportRequestOutput = {
  __typename?: 'CleaningReportsExportRequestOutput';
  requestId: Scalars['String'];
};

export type CleaningRoutine = {
  __typename?: 'CleaningRoutine';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  currentRepeat: Scalars['Int'];
  customerId: Scalars['String'];
  endDate?: Maybe<Scalars['AWSDateTime']>;
  estimatedEndDate?: Maybe<Scalars['AWSDateTime']>;
  executionId: Scalars['String'];
  machineId: Scalars['String'];
  name: Scalars['String'];
  numberOfRepeats: Scalars['Int'];
  routineCompletion?: Maybe<RoutineCompletion>;
  routineId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<RoutineStatus>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type CleaningTaskCleanedAreaByDay = {
  __typename?: 'CleaningTaskCleanedAreaByDay';
  cleanedArea: Scalars['Int'];
  finishedAt: Scalars['AWSDateTime'];
};

export type CleaningTaskCleanedAreaByDayList = {
  __typename?: 'CleaningTaskCleanedAreaByDayList';
  cleanedAreaList?: Maybe<Array<CleaningTaskCleanedAreaByDay>>;
  cleanedAreaSummary: CleanedAreaSummary;
};

export type CleaningTaskCleanedHrsByDay = {
  __typename?: 'CleaningTaskCleanedHrsByDay';
  cleanedHrs: Scalars['Float'];
  date: Scalars['AWSDateTime'];
};

export type CleaningTaskCleanedHrsByDayList = {
  __typename?: 'CleaningTaskCleanedHrsByDayList';
  cleanedHrsList?: Maybe<Array<CleaningTaskCleanedHrsByDay>>;
  cleanedHrsSummary: CleanedHrsSummary;
};

export type CleaningTaskConsumablesByDay = {
  __typename?: 'CleaningTaskConsumablesByDay';
  consumptions: RobotCleaningConsumption;
  finishedAt: Scalars['AWSDateTime'];
};

export type CleaningTaskConsumablesByDayList = {
  __typename?: 'CleaningTaskConsumablesByDayList';
  consumablesList?: Maybe<Array<CleaningTaskConsumablesByDay>>;
  consumablesSummary: ConsumablesSummary;
};

export type CleaningTaskDistanceDrivenByDay = {
  __typename?: 'CleaningTaskDistanceDrivenByDay';
  distanceDriven: Scalars['Float'];
  finishedAt: Scalars['AWSDateTime'];
};

export type CleaningTaskDistanceDrivenByDayList = {
  __typename?: 'CleaningTaskDistanceDrivenByDayList';
  distanceDrivenList?: Maybe<Array<CleaningTaskDistanceDrivenByDay>>;
  distanceDrivenSummary: DistanceDrivenSummary;
};

export type CleaningTaskReport = {
  __typename?: 'CleaningTaskReport';
  actualOperatingTimeInSeconds: Scalars['Int'];
  battery?: Maybe<Scalars['Float']>;
  classification: MachineClassification;
  cleanedSquareMeter?: Maybe<Scalars['Int']>;
  currentRepeat?: Maybe<Scalars['Int']>;
  customerId: Scalars['String'];
  detergents?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  executionId?: Maybe<Scalars['String']>;
  finishedAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  machine: Machine;
  machineName?: Maybe<Scalars['String']>;
  mapId?: Maybe<Scalars['String']>;
  materialNumber: Scalars['String'];
  pathCoverage?: Maybe<Scalars['Float']>;
  repeatPathId?: Maybe<Scalars['String']>;
  routeName?: Maybe<Scalars['String']>;
  routineName?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  startedAt: Scalars['AWSDateTime'];
  taskCompletion?: Maybe<Scalars['String']>;
  totalRepeats?: Maybe<Scalars['Int']>;
  water?: Maybe<Scalars['Float']>;
};

export enum Column {
  ActivationDate = 'ACTIVATION_DATE',
  AvgWorkingTimePerDay = 'AVG_WORKING_TIME_PER_DAY',
  BatteryDischargeStatus = 'BATTERY_DISCHARGE_STATUS',
  Category = 'CATEGORY',
  Classification = 'CLASSIFICATION',
  CleaningDays = 'CLEANING_DAYS',
  DataStatus = 'DATA_STATUS',
  Deviation = 'DEVIATION',
  FinanceContractNumber = 'FINANCE_CONTRACT_NUMBER',
  FinanceContractType = 'FINANCE_CONTRACT_TYPE',
  InventoryNumber = 'INVENTORY_NUMBER',
  LastConnection = 'LAST_CONNECTION',
  MachineName = 'MACHINE_NAME',
  MachineType = 'MACHINE_TYPE',
  MaterialNumber = 'MATERIAL_NUMBER',
  OperatingHours = 'OPERATING_HOURS',
  PlannedOperatingHours = 'PLANNED_OPERATING_HOURS',
  SerialNumber = 'SERIAL_NUMBER',
  ServiceAgreementType = 'SERVICE_AGREEMENT_TYPE',
  SiteManagers = 'SITE_MANAGERS',
  SiteName = 'SITE_NAME',
  TotalOperatingHours = 'TOTAL_OPERATING_HOURS'
}

export type ConsumablesSummary = {
  __typename?: 'ConsumablesSummary';
  periodBattery: Scalars['Float'];
  periodDetergents: Scalars['Float'];
  periodWater: Scalars['Float'];
};

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum DataStatus {
  GreaterThanThreeDaysOld = 'GREATER_THAN_THREE_DAYS_OLD',
  LessThanOneDayOld = 'LESS_THAN_ONE_DAY_OLD',
  NoData = 'NO_DATA',
  OneToThreeDaysOld = 'ONE_TO_THREE_DAYS_OLD'
}

export type DistanceDrivenSummary = {
  __typename?: 'DistanceDrivenSummary';
  periodDistanceDriven: Scalars['Float'];
  totalDistanceDriven: Scalars['Float'];
};

export type Field = {
  __typename?: 'Field';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type FilterActivatedAt = {
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};

export type FilterLastActivityAt = {
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};

export type Finance = {
  __typename?: 'Finance';
  contractNumber?: Maybe<Scalars['String']>;
  financeType?: Maybe<FinanceType>;
};

export enum FinanceType {
  HirePurchase = 'HIRE_PURCHASE',
  Leasing = 'LEASING',
  Purchase = 'PURCHASE',
  Rental = 'RENTAL'
}

export type FleetOperatingTime = {
  __typename?: 'FleetOperatingTime';
  actualTotalOperatingTimeS: Scalars['Long'];
  plannedTotalOperatingTimeS: Scalars['Long'];
};

export type GetExportFileOutput = {
  __typename?: 'GetExportFileOutput';
  objectKey?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
  status: AsyncJobStatus;
};

export type GetExportOutput = {
  __typename?: 'GetExportOutput';
  requestId: Scalars['String'];
  status: AsyncJobStatus;
};

export type InputAcceptTermsAndCondition = {
  contractObligationVersion: Scalars['String'];
  licenseAgreementVersion: Scalars['String'];
};

export type InputCircle = {
  centerPoint: InputCoordinate;
  radius: Scalars['Float'];
};

export type InputCleaningReportSubscription = {
  formats: Array<CleaningReportSubscriptionReportFormat>;
  interval: CleaningReportSubscriptionInterval;
  timezone?: InputMaybe<Scalars['String']>;
  type: CleaningReportSubscriptionType;
};

export type InputCoordinate = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type InputFilterCleaningConsumption = {
  machineIds?: InputMaybe<Array<Scalars['String']>>;
  machines?: InputMaybe<InputMachineFilter>;
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
  taskCompletions?: InputMaybe<Array<TaskCompletion>>;
};

export type InputFilterCleaningReportsExportRequest = {
  machineIds?: InputMaybe<Array<Scalars['ID']>>;
  period: InputPeriod;
  reportType: ReportType;
};

export type InputFilterCleaningTaskCleanedAreaByDayList = {
  machineId: Scalars['String'];
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type InputFilterCleaningTaskCleanedHrsByDayList = {
  machineId: Scalars['String'];
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
  timeZone: Scalars['String'];
};

export type InputFilterCleaningTaskConsumablesByDayList = {
  machineId: Scalars['String'];
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type InputFilterCleaningTaskDistanceDrivenByDayList = {
  machineId: Scalars['String'];
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type InputFilterCleaningTaskReportsList = {
  machineIds?: InputMaybe<Array<Scalars['String']>>;
  machines?: InputMaybe<InputMachineFilter>;
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
  taskCompletions?: InputMaybe<Array<TaskCompletion>>;
};

export type InputFilterFleetOperatingTime = {
  period: InputPeriod;
};

export type InputFilterMachineIdsList = {
  machineIds?: InputMaybe<Array<Scalars['ID']>>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type InputFilterMachinesList = {
  classifications?: InputMaybe<Array<MachineClassification>>;
  connectionStatuses?: InputMaybe<Array<MachineConnectionStatus>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isIoTDevice?: InputMaybe<Scalars['Boolean']>;
  isRobotStatusEmpty?: InputMaybe<Scalars['Boolean']>;
  isStandalone?: InputMaybe<Scalars['Boolean']>;
  lastActivityAt?: InputMaybe<FilterLastActivityAt>;
  materialNumbers?: InputMaybe<Array<Scalars['String']>>;
  robotStatuses?: InputMaybe<Array<RobotStatus>>;
  searchText?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type InputFilterMachinesReport = {
  activatedAt?: InputMaybe<FilterActivatedAt>;
  classifications?: InputMaybe<Array<MachineClassification>>;
  connectionStatuses?: InputMaybe<Array<MachineConnectionStatus>>;
  dataStatuses?: InputMaybe<Array<DataStatus>>;
  financeTypes?: InputMaybe<Array<FinanceType>>;
  isIoTDevice?: InputMaybe<Scalars['Boolean']>;
  lastActivityAt?: InputMaybe<FilterLastActivityAt>;
  machineIds?: InputMaybe<Array<Scalars['ID']>>;
  materialNumbers?: InputMaybe<Array<Scalars['String']>>;
  servicePackages?: InputMaybe<Array<ServicePackage>>;
  siteIds?: InputMaybe<Array<Scalars['ID']>>;
  softwareVersion?: InputMaybe<Scalars['String']>;
  subclassifications?: InputMaybe<Array<MachineSubclassification>>;
};

export type InputFilterNotificationsList = {
  categories?: InputMaybe<Array<NotificationCategory>>;
  machineIds?: InputMaybe<Array<Scalars['ID']>>;
  period?: InputMaybe<InputPeriod>;
  severities?: InputMaybe<Array<Severity>>;
  type?: InputMaybe<Type>;
};

export type InputFilterRobotCleaningRoutesList = {
  machineId: Scalars['String'];
  period: InputPeriod;
};

export type InputFilterRobotDashboardKpIsGet = {
  executionId?: InputMaybe<Scalars['String']>;
  machineIds?: InputMaybe<Array<Scalars['String']>>;
  machines?: InputMaybe<InputMachineFilter>;
  period?: InputMaybe<InputPeriod>;
  routeName?: InputMaybe<Scalars['String']>;
  taskCompletions?: InputMaybe<Array<TaskCompletion>>;
};

export type InputFilterRobotDashboardTotalCleanedAreaGet = {
  machineIds?: InputMaybe<Array<Scalars['String']>>;
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
};

export type InputFilterRobotDashboardTotalCleanedHrsGet = {
  machineIds?: InputMaybe<Array<Scalars['String']>>;
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
};

export type InputFilterRobotTaskCompletionStatisticGet = {
  machineIds?: InputMaybe<Array<Scalars['String']>>;
  period: InputPeriod;
  routeName?: InputMaybe<Scalars['String']>;
};

export type InputFilterRoutineCleaningTaskReport = {
  executionId: Scalars['String'];
};

export type InputFilterSingleRoutesAndRoutinesList = {
  machineIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period: InputPeriod;
  siteNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taskCompletions?: InputMaybe<Array<InputMaybe<TaskCompletion>>>;
};

export type InputFilterSiteAddressCoordinateGet = {
  text: Scalars['String'];
};

export type InputFilterSiteAddressesSuggestionList = {
  text: Scalars['String'];
};

export type InputFilterSiteCleaningStatistic = {
  classifications: Array<MachineClassification>;
  connectionStatuses?: InputMaybe<Array<MachineConnectionStatus>>;
  machineIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period: InputPeriod;
  robotStatuses?: InputMaybe<Array<RobotStatus>>;
};

export type InputFilterSiteMachinesList = {
  classifications?: InputMaybe<Array<MachineClassification>>;
  connectionStatuses?: InputMaybe<Array<MachineConnectionStatus>>;
  isRobotStatusEmpty?: InputMaybe<Scalars['Boolean']>;
  robotStatuses?: InputMaybe<Array<RobotStatus>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type InputFilterSitesList = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type InputFilterTagsItemsGet = {
  itemType: ItemType;
  tags: Array<Scalars['String']>;
};

export type InputFilterTagsList = {
  textBegin?: InputMaybe<Scalars['String']>;
};

export type InputFilterUsersList = {
  role?: InputMaybe<Role>;
  status?: InputMaybe<Status>;
};

export type InputFilterWorkIntervalsList = {
  siteId: Scalars['ID'];
};

export type InputFinance = {
  contractNumber?: InputMaybe<Scalars['String']>;
  financeType?: InputMaybe<FinanceType>;
};

export type InputMachineFilter = {
  classifications?: InputMaybe<Array<MachineClassification>>;
};

export type InputMachineFilterFilter = {
  classifications?: InputMaybe<Array<MachineClassification>>;
  dataStatuses?: InputMaybe<Array<DataStatus>>;
  financeTypes?: InputMaybe<Array<FinanceType>>;
  materialNumbers?: InputMaybe<Array<Scalars['String']>>;
  servicePackages?: InputMaybe<Array<ServicePackage>>;
  siteIds?: InputMaybe<Array<Scalars['ID']>>;
  subclassifications?: InputMaybe<Array<MachineSubclassification>>;
};

export type InputMachineNoteCreate = {
  description: Scalars['String'];
  machineId: Scalars['String'];
};

export type InputMachineNoteUpdate = {
  description: Scalars['String'];
  id: Scalars['ID'];
  machineId: Scalars['String'];
};

export type InputMachineUpdate = {
  finance?: InputMaybe<InputFinance>;
  inventoryNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  servicePackage?: InputMaybe<ServicePackage>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type InputMachinesReportSubscription = {
  lang: SupportedLanguage;
  reportFileFormats: Array<MachinesReportSubscriptionReportFileFormat>;
  reportInterval: MachinesReportSubscriptionReportInterval;
  reportType: MachinesReportSubscriptionReportType;
};

export type InputNoteCreator = {
  displayName: Scalars['String'];
  id: Scalars['String'];
};

export type InputNotificationUpdateNotifyStatus = {
  assignedMachines: Array<InputMaybe<AssignedMachineInput>>;
  countryCode?: InputMaybe<Scalars['String']>;
  emailSubscription?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<Scalars['String']>;
  notifyAutonomousTaskStopped: Scalars['Boolean'];
  notifyBatteryDeepDischarge: Scalars['Boolean'];
  notifyCleaningTaskFinished: Scalars['Boolean'];
  notifyCleaningTaskInterrupted: Scalars['Boolean'];
  notifyEmergencyStop: Scalars['Boolean'];
  notifyErrorOccurred: Scalars['Boolean'];
  notifyInformation: Scalars['Boolean'];
  notifyLocationStatus: Scalars['Boolean'];
  notifyNoWorkStart: Scalars['Boolean'];
  notifyOther: Scalars['Boolean'];
  notifyPCM: Scalars['Boolean'];
  notifyReminder: Scalars['Boolean'];
  notifyResources: Scalars['Boolean'];
  notifyServiceNeeded: Scalars['Boolean'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  smsSubscription?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type InputNotificationUpsertOperator = {
  assignedMachines: Array<AssignedMachineInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailSubscription?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  name: Scalars['String'];
  notifications?: InputMaybe<InputOperatorNotificationUpdateNotifyStatus>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  smsSubscription?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type InputOperatorNotificationUpdateNotifyStatus = {
  notifyAutonomousTaskStopped: Scalars['Boolean'];
  notifyBatteryDeepDischarge: Scalars['Boolean'];
  notifyCleaningTaskFinished: Scalars['Boolean'];
  notifyCleaningTaskInterrupted: Scalars['Boolean'];
  notifyEmergencyStop: Scalars['Boolean'];
  notifyErrorOccurred: Scalars['Boolean'];
  notifyInformation: Scalars['Boolean'];
  notifyLocationStatus: Scalars['Boolean'];
  notifyNoWorkStart: Scalars['Boolean'];
  notifyOther: Scalars['Boolean'];
  notifyPCM: Scalars['Boolean'];
  notifyReminder: Scalars['Boolean'];
  notifyResources: Scalars['Boolean'];
  notifyServiceNeeded: Scalars['Boolean'];
};

export type InputPagingOptions = {
  limit: Scalars['Int'];
  paginationToken?: InputMaybe<Scalars['String']>;
};

export type InputPeriod = {
  endAt: Scalars['AWSDateTime'];
  startAt: Scalars['AWSDateTime'];
};

export type InputReminderCreate = {
  description: Scalars['String'];
  localRepetitiveDate: Scalars['AWSDate'];
  localRepetitiveTime: Scalars['AWSTime'];
  machineId: Scalars['String'];
  repetition: RepetitionInterval;
  startAt: Scalars['AWSDateTime'];
  timezone: Scalars['String'];
  topic: Scalars['String'];
};

export type InputSiteAddressesSuggestionListPagingOptions = {
  limit: Scalars['Int'];
};

export type InputSiteAssignMachine = {
  machineIds: Array<InputMaybe<Scalars['ID']>>;
  siteId: Scalars['ID'];
};

export type InputSiteAssignManagers = {
  siteId: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type InputSiteCreate = {
  location?: InputMaybe<InputSiteLocation>;
  name: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type InputSiteGeofenceSetup = {
  circle: InputCircle;
};

export type InputSiteLocation = {
  address?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type InputSiteTagAdd = {
  tag: Scalars['String'];
};

export type InputSiteTagRemove = {
  tag: Scalars['String'];
};

export type InputSiteTagsOverride = {
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type InputSiteUnassignMachine = {
  machineId: Scalars['ID'];
  siteId: Scalars['ID'];
};

export type InputSiteUnassignManager = {
  siteId: Scalars['String'];
  userId: Scalars['String'];
};

export type InputSiteUpdate = {
  location?: InputMaybe<InputSiteLocation>;
  name?: InputMaybe<Scalars['String']>;
};

export type InputSortOptions = {
  field: Scalars['String'];
  order: SortOrders;
};

export type InputUserDeleteByEmail = {
  email: Scalars['String'];
};

export type InputUserInvite = {
  assignedSiteIds?: InputMaybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  role: Role;
  salutation?: InputMaybe<Scalars['String']>;
};

export type InputUserUpdate = {
  role: Role;
};

export type InputWorkIntervalCreate = {
  createdAt: Scalars['AWSDateTime'];
  durationMs: Scalars['Int'];
  machineId: Scalars['String'];
  recurring: RecurrenceInterval;
  startHoursUtc: Scalars['Int'];
  startMinutesUtc: Scalars['Int'];
  weekdaysUtc: Array<Scalars['Int']>;
};

export type InputWorkIntervalUpdate = {
  machineId?: InputMaybe<Scalars['String']>;
  timeSpan?: InputMaybe<TimeSpan>;
  weekdaysUtc?: InputMaybe<Array<Scalars['Int']>>;
};

export enum ItemType {
  General = 'GENERAL'
}

export type Location = {
  __typename?: 'Location';
  accuracy: Scalars['Float'];
  address?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Machine = {
  __typename?: 'Machine';
  activationDate: Scalars['AWSDateTime'];
  attachments?: Maybe<ResponseMachineAttachmentsList>;
  batteryDischargeStatus?: Maybe<MachineBatteryDischargeStatus>;
  classification?: Maybe<MachineClassification>;
  cleaningTaskReportLatestGet?: Maybe<ResponseLatestCleaningTaskReport>;
  connectionStatus: MachineConnectionStatus;
  customerId: Scalars['String'];
  id: Scalars['ID'];
  isIoTDevice?: Maybe<Scalars['Boolean']>;
  lastActivityAt?: Maybe<Scalars['AWSDateTime']>;
  lastConnectedDate?: Maybe<Scalars['AWSDateTime']>;
  latestRoutine?: Maybe<ResponseLatestRoutine>;
  location?: Maybe<Location>;
  materialNumber: Scalars['String'];
  metadata?: Maybe<MachineMetadata>;
  name: Scalars['String'];
  notes?: Maybe<ResponseMachineNotesList>;
  operatingTimeForPeriod?: Maybe<OperatingTimePeriod>;
  reminders?: Maybe<ResponseRemindersList>;
  robotStatus?: Maybe<RobotStatus>;
  serialNumber: Scalars['String'];
  site?: Maybe<Site>;
  softwareVersion?: Maybe<Scalars['String']>;
  states?: Maybe<Array<State>>;
  totalOperatingTime?: Maybe<Scalars['Int']>;
  type?: Maybe<MachineType>;
  variant: MachineVariant;
};


export type MachineAttachmentsArgs = {
  paginationOptions: InputPagingOptions;
  sortOptions?: InputMaybe<InputSortOptions>;
};


export type MachineNotesArgs = {
  paginationOptions: InputPagingOptions;
};


export type MachineOperatingTimeForPeriodArgs = {
  period: InputPeriod;
};


export type MachineRemindersArgs = {
  paginationOptions: InputPagingOptions;
};

export enum MachineBatteryDischargeStatus {
  Critical = 'CRITICAL',
  Ok = 'OK',
  Warning = 'WARNING'
}

export enum MachineClassification {
  Gcd = 'GCD',
  NonIot = 'NON_IOT',
  Robot = 'ROBOT'
}

export enum MachineConnectionStatus {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Unknown = 'UNKNOWN'
}

export type MachineFilter = {
  __typename?: 'MachineFilter';
  classifications: Array<MachineClassification>;
  dataStatuses: Array<DataStatus>;
  financeTypes: Array<FinanceType>;
  machineTypes: Array<MachineTypeFilter>;
  servicePackages: Array<ServicePackage>;
  sites: Array<SiteFilter>;
  subclassifications: Array<MachineSubclassification>;
};

export type MachineId = {
  __typename?: 'MachineId';
  id: Scalars['ID'];
};

export type MachineMetadata = {
  __typename?: 'MachineMetadata';
  finance?: Maybe<Finance>;
  inventoryNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  service?: Maybe<Service>;
};

export type MachineNote = {
  __typename?: 'MachineNote';
  createdAt: Scalars['AWSDateTime'];
  createdBy: NoteCreator;
  description: Scalars['String'];
  id: Scalars['ID'];
  isUpdated: Scalars['Boolean'];
  machineId: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type MachineStatistics = {
  __typename?: 'MachineStatistics';
  actualOperatingTimeInMs: Scalars['Long'];
  avgWorkingTimePerDayInMs: Scalars['Long'];
  cleaningDays: Scalars['Int'];
  deviationInMs: Scalars['Float'];
  deviationInPercentage?: Maybe<Scalars['Float']>;
  endAt: Scalars['AWSDateTime'];
  plannedOperatingTimeInMs: Scalars['Long'];
  startAt: Scalars['AWSDateTime'];
};

export enum MachineSubclassification {
  Iot = 'IOT',
  NonIot = 'NON_IOT'
}

export type MachineType = {
  __typename?: 'MachineType';
  materialNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MachineTypeByName = {
  __typename?: 'MachineTypeByName';
  materialNumbers?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
};

export type MachineTypeFilter = {
  __typename?: 'MachineTypeFilter';
  materialNumbers: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type MachineUpdate = {
  __typename?: 'MachineUpdate';
  customerId: Scalars['String'];
  data?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  machineId: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
};

export type MachineVariant = {
  __typename?: 'MachineVariant';
  id: Scalars['ID'];
  materialNumber: Scalars['String'];
  picture: MachineVariantPicture;
};

export type MachineVariantPicture = {
  __typename?: 'MachineVariantPicture';
  overview: Scalars['String'];
  product: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type MachineVncRequestInput = {
  machineId: Scalars['ID'];
};

export type MachineVncRequestResponse = {
  __typename?: 'MachineVncRequestResponse';
  expiresAt: Scalars['AWSDateTime'];
  machineId: Scalars['String'];
  url: Scalars['String'];
};

export type MachineVncRequestResponseV2 = {
  __typename?: 'MachineVncRequestResponseV2';
  expiresAt: Scalars['AWSDateTime'];
  region: Scalars['String'];
  sessionId: Scalars['String'];
  token: Scalars['String'];
};

export enum MachineVncStatus {
  Failed = 'FAILED',
  Running = 'RUNNING',
  Waiting = 'WAITING'
}

export type MachineVncStatusGetInput = {
  machineId: Scalars['ID'];
};

export type MachineVncStatusGetInputV2 = {
  machineId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type MachineVncStatusGetResponse = {
  __typename?: 'MachineVncStatusGetResponse';
  code: Array<Scalars['String']>;
  status: MachineVncStatus;
};

export type MachinesExportFile = {
  __typename?: 'MachinesExportFile';
  downloadUrl?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  status: AsyncJobStatus;
};

export type MachinesListExportRequestOutput = {
  __typename?: 'MachinesListExportRequestOutput';
  requestId: Scalars['String'];
};

export type MachinesReport = {
  __typename?: 'MachinesReport';
  activatedAt: Scalars['AWSDateTime'];
  batteryDischargeStatus?: Maybe<BatteryDischargeStatus>;
  classification?: Maybe<MachineClassification>;
  connectionStatus: MachineConnectionStatus;
  customerId: Scalars['String'];
  isIoTDevice?: Maybe<Scalars['Boolean']>;
  lastActivityAt?: Maybe<Scalars['AWSDateTime']>;
  lastConnectedAt?: Maybe<Scalars['AWSDateTime']>;
  machineId: Scalars['ID'];
  machineTypeName: Scalars['String'];
  materialNumber: Scalars['String'];
  metadata?: Maybe<MachineMetadata>;
  serialNumber: Scalars['String'];
  site?: Maybe<MachinesReportSite>;
  softwareVersion?: Maybe<Scalars['String']>;
  statistics?: Maybe<MachineStatistics>;
  totalOperatingTimeInSeconds: Scalars['Int'];
};

export type MachinesReportExport = {
  __typename?: 'MachinesReportExport';
  requestId: Scalars['String'];
};

export type MachinesReportSite = {
  __typename?: 'MachinesReportSite';
  managers: Array<MachinesReportUser>;
  name: Scalars['String'];
  siteId: Scalars['ID'];
};

export type MachinesReportSubscription = {
  __typename?: 'MachinesReportSubscription';
  customerId: Scalars['String'];
  email: Scalars['AWSEmail'];
  lang: SupportedLanguage;
  reportFileFormats: Array<MachinesReportSubscriptionReportFileFormat>;
  reportInterval: MachinesReportSubscriptionReportInterval;
  reportType: MachinesReportSubscriptionReportType;
  userId: Scalars['String'];
};

export enum MachinesReportSubscriptionReportFileFormat {
  Excel = 'EXCEL',
  Pdf = 'PDF'
}

export enum MachinesReportSubscriptionReportInterval {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum MachinesReportSubscriptionReportType {
  Total = 'TOTAL'
}

export type MachinesReportUser = {
  __typename?: 'MachinesReportUser';
  email: Scalars['String'];
  fullName: Scalars['String'];
  userId: Scalars['ID'];
};

export type Manager = {
  __typename?: 'Manager';
  userId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsAndCondition?: Maybe<ResponseAcceptTermsAndCondition>;
  cleaningReportSubscriptionUpdateBatch?: Maybe<ResponseCleaningReportSubscriptions>;
  cleaningReportsExportRequest?: Maybe<ResponseCleaningReportsExportRequest>;
  machineAttachmentDelete?: Maybe<ResponseAttachmentDelete>;
  machineNoteCreate?: Maybe<ResponseMachineNoteCreate>;
  machineNoteDelete?: Maybe<ResponseBoolean>;
  machineNoteUpdate?: Maybe<ResponseMachineNoteUpdate>;
  machineUpdate?: Maybe<ResponseMachineUpdate>;
  machineVncRequest?: Maybe<ResponseMachineVncRequest>;
  machineVncRequestV2?: Maybe<ResponseMachineVncRequestV2>;
  machinesListExportRequest?: Maybe<ResponseMachinesListExportRequest>;
  machinesReportExportRequest?: Maybe<ResponseMachinesReportExportRequest>;
  machinesReportSubscriptionsReset?: Maybe<ResponseMachinesReportSubscriptions>;
  notificationDeleteOperator?: Maybe<ResponseNotificationDeleteOperator>;
  notificationUpdateNotifyStatus?: Maybe<ResponseNotificationUpdateNotifyStatus>;
  notificationUpsertOperator?: Maybe<ResponseNotificationUpsertOperator>;
  publishMachineUpdate?: Maybe<MachineUpdate>;
  reminderCreate?: Maybe<ResponseReminderCreate>;
  reminderDelete?: Maybe<ResponseBoolean>;
  robotDetailsReportsExportRequest?: Maybe<ResponseRobotDetailsReportsExportRequest>;
  routineReportExportRequest?: Maybe<ResponseRoutineReportExportRequest>;
  siteAssignMachine?: Maybe<ResponseSiteAssignMachine>;
  siteAssignManagers?: Maybe<ResponseSiteAssignManagers>;
  siteCreate?: Maybe<ResponseSiteCreate>;
  siteDelete?: Maybe<ResponseBoolean>;
  siteGeofenceSetup?: Maybe<ResponseBoolean>;
  siteTagAdd?: Maybe<ResponseSiteTagAdd>;
  siteTagRemove?: Maybe<ResponseBoolean>;
  siteTagsOverride?: Maybe<ResponseSiteTagsOverride>;
  siteUnassignMachine?: Maybe<ResponseSiteUnassignMachine>;
  siteUnassignManager?: Maybe<ResponseSiteUnassignManager>;
  siteUpdate?: Maybe<ResponseSiteUpdate>;
  userDeleteByEmail?: Maybe<ResponseBoolean>;
  userInvite?: Maybe<ResponseUserInvite>;
  userUnauthorize?: Maybe<ResponseUserUnauthorize>;
  userUpdate?: Maybe<ResponseUserUpdate>;
  workIntervalCreate?: Maybe<ResponseWorkIntervalCreate>;
  workIntervalDelete?: Maybe<ResponseBoolean>;
  workIntervalUpdate?: Maybe<ResponseWorkIntervalUpdate>;
};


export type MutationAcceptTermsAndConditionArgs = {
  input: InputAcceptTermsAndCondition;
};


export type MutationCleaningReportSubscriptionUpdateBatchArgs = {
  cleaningReportSubscriptions: Array<InputCleaningReportSubscription>;
  lang: SupportedLanguage;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationCleaningReportsExportRequestArgs = {
  filter: InputFilterCleaningReportsExportRequest;
  lang: SupportedLanguage;
  sortOptions?: InputMaybe<InputSortOptions>;
  timezone: Scalars['String'];
};


export type MutationMachineAttachmentDeleteArgs = {
  attachmentId: Scalars['ID'];
  machineId: Scalars['ID'];
};


export type MutationMachineNoteCreateArgs = {
  input: InputMachineNoteCreate;
};


export type MutationMachineNoteDeleteArgs = {
  id: Scalars['ID'];
  machineId: Scalars['String'];
};


export type MutationMachineNoteUpdateArgs = {
  input: InputMachineNoteUpdate;
};


export type MutationMachineUpdateArgs = {
  id: Scalars['ID'];
  input: InputMachineUpdate;
};


export type MutationMachineVncRequestArgs = {
  input: MachineVncRequestInput;
};


export type MutationMachineVncRequestV2Args = {
  input: MachineVncRequestInput;
};


export type MutationMachinesListExportRequestArgs = {
  filter?: InputMaybe<InputFilterMachinesList>;
  lang: SupportedLanguage;
  sortOptions?: InputMaybe<InputSortOptions>;
  timezone: Scalars['String'];
};


export type MutationMachinesReportExportRequestArgs = {
  columns?: InputMaybe<Array<Column>>;
  filter: InputFilterMachinesReport;
  lang: SupportedLanguage;
  period: InputPeriod;
  search?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<InputSortOptions>;
  timezone: Scalars['String'];
};


export type MutationMachinesReportSubscriptionsResetArgs = {
  input: Array<InputMachinesReportSubscription>;
};


export type MutationNotificationDeleteOperatorArgs = {
  id: Scalars['String'];
};


export type MutationNotificationUpdateNotifyStatusArgs = {
  input: InputNotificationUpdateNotifyStatus;
};


export type MutationNotificationUpsertOperatorArgs = {
  input: InputNotificationUpsertOperator;
};


export type MutationPublishMachineUpdateArgs = {
  customerId: Scalars['String'];
  data?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  machineId: Scalars['String'];
  publishedAt: Scalars['String'];
};


export type MutationReminderCreateArgs = {
  input: InputReminderCreate;
};


export type MutationReminderDeleteArgs = {
  id: Scalars['ID'];
  machineId: Scalars['ID'];
};


export type MutationRobotDetailsReportsExportRequestArgs = {
  id: Scalars['String'];
  lang: SupportedLanguage;
  machineId: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationRoutineReportExportRequestArgs = {
  executionId: Scalars['String'];
  lang: SupportedLanguage;
  timezone: Scalars['String'];
};


export type MutationSiteAssignMachineArgs = {
  input: InputSiteAssignMachine;
};


export type MutationSiteAssignManagersArgs = {
  input: InputSiteAssignManagers;
};


export type MutationSiteCreateArgs = {
  input: InputSiteCreate;
};


export type MutationSiteDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationSiteGeofenceSetupArgs = {
  id: Scalars['ID'];
  input: InputSiteGeofenceSetup;
};


export type MutationSiteTagAddArgs = {
  id: Scalars['ID'];
  input: InputSiteTagAdd;
};


export type MutationSiteTagRemoveArgs = {
  id: Scalars['ID'];
  input: InputSiteTagRemove;
};


export type MutationSiteTagsOverrideArgs = {
  id: Scalars['ID'];
  input: InputSiteTagsOverride;
};


export type MutationSiteUnassignMachineArgs = {
  input: InputSiteUnassignMachine;
};


export type MutationSiteUnassignManagerArgs = {
  input: InputSiteUnassignManager;
};


export type MutationSiteUpdateArgs = {
  id: Scalars['ID'];
  input: InputSiteUpdate;
};


export type MutationUserDeleteByEmailArgs = {
  input: InputUserDeleteByEmail;
};


export type MutationUserInviteArgs = {
  input: InputUserInvite;
};


export type MutationUserUnauthorizeArgs = {
  id: Scalars['ID'];
};


export type MutationUserUpdateArgs = {
  id: Scalars['ID'];
  input: InputUserUpdate;
};


export type MutationWorkIntervalCreateArgs = {
  input: InputWorkIntervalCreate;
};


export type MutationWorkIntervalDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationWorkIntervalUpdateArgs = {
  id: Scalars['ID'];
  input: InputWorkIntervalUpdate;
};

export type NoteCreator = {
  __typename?: 'NoteCreator';
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  data?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  machine: Machine;
  message?: Maybe<NotificationMessage>;
  severity: Scalars['String'];
  timestamp: Scalars['AWSDateTime'];
  type: Scalars['String'];
};

export enum NotificationCategory {
  NotifyAutonomousTaskStopped = 'notifyAutonomousTaskStopped',
  NotifyBatteryDeepDischarge = 'notifyBatteryDeepDischarge',
  NotifyCleaningTaskFinished = 'notifyCleaningTaskFinished',
  NotifyCleaningTaskInterrupted = 'notifyCleaningTaskInterrupted',
  NotifyEmergencyStop = 'notifyEmergencyStop',
  NotifyErrorOccurred = 'notifyErrorOccurred',
  NotifyInformation = 'notifyInformation',
  NotifyLocationStatus = 'notifyLocationStatus',
  NotifyNoWorkStart = 'notifyNoWorkStart',
  NotifyOther = 'notifyOther',
  NotifyPcm = 'notifyPCM',
  NotifyReminder = 'notifyReminder',
  NotifyResources = 'notifyResources',
  NotifyServiceNeeded = 'notifyServiceNeeded'
}

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  cause?: Maybe<Scalars['String']>;
  consequence?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type NotificationOperator = {
  __typename?: 'NotificationOperator';
  assignedMachines: Array<Maybe<AssignedMachineType>>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailSubscription?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  name: Scalars['String'];
  notifyAutonomousTaskStopped?: Maybe<Scalars['Boolean']>;
  notifyBatteryDeepDischarge?: Maybe<Scalars['Boolean']>;
  notifyCleaningTaskFinished?: Maybe<Scalars['Boolean']>;
  notifyCleaningTaskInterrupted?: Maybe<Scalars['Boolean']>;
  notifyEmergencyStop?: Maybe<Scalars['Boolean']>;
  notifyErrorOccurred?: Maybe<Scalars['Boolean']>;
  notifyInformation?: Maybe<Scalars['Boolean']>;
  notifyLocationStatus?: Maybe<Scalars['Boolean']>;
  notifyNoWorkStart?: Maybe<Scalars['Boolean']>;
  notifyOther?: Maybe<Scalars['Boolean']>;
  notifyPCM?: Maybe<Scalars['Boolean']>;
  notifyReminder?: Maybe<Scalars['Boolean']>;
  notifyResources?: Maybe<Scalars['Boolean']>;
  notifyServiceNeeded?: Maybe<Scalars['Boolean']>;
  numberOfAssignedMachines?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  smsSubscription?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
};

export enum NotificationSeverity {
  Alert = 'ALERT',
  Informative = 'INFORMATIVE',
  NeedForAction = 'NEED_FOR_ACTION'
}

export type NotificationUser = {
  __typename?: 'NotificationUser';
  assignedMachines: Array<Maybe<AssignedMachineType>>;
  countryCode?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailSubscription?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  notifyAutonomousTaskStopped?: Maybe<Scalars['Boolean']>;
  notifyBatteryDeepDischarge?: Maybe<Scalars['Boolean']>;
  notifyCleaningTaskFinished?: Maybe<Scalars['Boolean']>;
  notifyCleaningTaskInterrupted?: Maybe<Scalars['Boolean']>;
  notifyEmergencyStop?: Maybe<Scalars['Boolean']>;
  notifyErrorOccurred?: Maybe<Scalars['Boolean']>;
  notifyInformation?: Maybe<Scalars['Boolean']>;
  notifyLocationStatus?: Maybe<Scalars['Boolean']>;
  notifyNoWorkStart?: Maybe<Scalars['Boolean']>;
  notifyOther?: Maybe<Scalars['Boolean']>;
  notifyPCM?: Maybe<Scalars['Boolean']>;
  notifyReminder?: Maybe<Scalars['Boolean']>;
  notifyResources?: Maybe<Scalars['Boolean']>;
  notifyServiceNeeded?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  smsSubscription?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
};

export type OperatingTimeInterval = {
  __typename?: 'OperatingTimeInterval';
  actualOperatingTimeMs: Scalars['Long'];
  deviationMs: Scalars['Long'];
  endAt: Scalars['AWSDateTime'];
  plannedOperatingTimeMs: Scalars['Long'];
  startAt: Scalars['AWSDateTime'];
};

export type OperatingTimePeriod = {
  __typename?: 'OperatingTimePeriod';
  actualTotalOperatingTimeMs: Scalars['Long'];
  endAt: Scalars['AWSDateTime'];
  operatingTimeIntervals?: Maybe<Array<OperatingTimeInterval>>;
  plannedTotalOperatingTimeMs: Scalars['Long'];
  startAt: Scalars['AWSDateTime'];
};


export type OperatingTimePeriodOperatingTimeIntervalsArgs = {
  timezone: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  attachmentDownloadLinkGet?: Maybe<ResponseAttachmentDownloadLinkGet>;
  attachmentNameCheck?: Maybe<ResponseAttachmentNameCheck>;
  attachmentUploadLinkGet?: Maybe<ResponseAttachmentUploadLinkGet>;
  cleaningReportSubscriptions?: Maybe<ResponseCleaningReportSubscriptions>;
  cleaningReportsExportGetFile?: Maybe<ResponseGetCleaningReportsExportFile>;
  cleaningTaskCleanedAreaByDayList?: Maybe<ResponseCleaningTaskCleanedAreaByDayList>;
  cleaningTaskCleanedHrsByDayList?: Maybe<ResponseCleaningTaskCleanedHrsByDayList>;
  cleaningTaskConsumablesByDayList?: Maybe<ResponseCleaningTaskConsumablesByDayList>;
  cleaningTaskDistanceDrivenByDayList?: Maybe<ResponseCleaningTaskDistanceDrivenByDayList>;
  cleaningTaskReportRobotRouteImage?: Maybe<ResponseString>;
  cleaningTaskReports?: Maybe<ResponseCleaningTaskReportsList>;
  fleetOperatingTime?: Maybe<ResponseFleetOperatingTime>;
  machine?: Maybe<ResponseMachineGet>;
  machineFilter?: Maybe<ResponseMachineFilter>;
  machineIds?: Maybe<ResponseMachineIdsList>;
  machineTypes?: Maybe<ResponseMachineTypesList>;
  machineVariants?: Maybe<ResponseMachineVariantsList>;
  machineVncStatusGet?: Maybe<ResponseMachineVncStatusGet>;
  machineVncStatusGetV2?: Maybe<ResponseMachineVncStatusGetV2>;
  machines?: Maybe<ResponseMachinesList>;
  machinesAvailableToBeAssigned?: Maybe<ResponseMachinesAvailableToBeAssigned>;
  machinesExportFile?: Maybe<ResponseMachinesExportFile>;
  machinesReport?: Maybe<ResponseMachinesReportList>;
  machinesReportExportGet?: Maybe<ResponseGetMachinesReportExport>;
  machinesReportSubscriptionsList?: Maybe<ResponseMachinesReportSubscriptions>;
  notificationGetOperator?: Maybe<ResponseNotificationGetOperator>;
  notificationGetUser?: Maybe<ResponseNotificationUser>;
  notificationListOperator?: Maybe<ResponseNotificationListOperator>;
  notifications?: Maybe<ResponseNotificationsList>;
  robotCleaningRoutesList?: Maybe<ResponseRobotCleaningRoutesList>;
  robotConsumptionSummary?: Maybe<ResponseRobotCleaningConsumptionSummary>;
  robotConsumptionsList?: Maybe<ResponseRobotCleaningConsumptionsList>;
  robotDashboardKPIs?: Maybe<ResponseRobotDashboardKpIsGet>;
  robotDashboardTotalCleanedArea?: Maybe<ResponseRobotDashboardTotalCleanedAreaGet>;
  robotDashboardTotalCleanedHrs?: Maybe<ResponseRobotDashboardTotalCleanedHrsGet>;
  robotTaskCompletionStatistic?: Maybe<ResponseRobotTaskCompletionStatisticGet>;
  routineCleaningTaskReport?: Maybe<ResponseRoutineCleaningTaskReport>;
  singleRoutesAndRoutinesList?: Maybe<ResponseSingleRoutesAndRoutinesList>;
  site?: Maybe<ResponseSiteGet>;
  siteAddressCoordinate?: Maybe<ResponseSiteAddressCoordinateGet>;
  siteAddressesSuggestion?: Maybe<ResponseSiteAddressesSuggestionList>;
  sites?: Maybe<ResponseSitesList>;
  tags?: Maybe<ResponseTagsList>;
  tagsItems?: Maybe<ResponseTagsItemsGet>;
  usermachinesClassificationsCheck?: Maybe<ResponseCheckAccessibleClassification>;
  users?: Maybe<ResponseUsersList>;
  workIntervals?: Maybe<ResponseWorkIntervalsList>;
};


export type QueryAttachmentDownloadLinkGetArgs = {
  attachmentId: Scalars['String'];
  machineId: Scalars['ID'];
};


export type QueryAttachmentNameCheckArgs = {
  attachmentName: Scalars['String'];
  machineId: Scalars['ID'];
};


export type QueryAttachmentUploadLinkGetArgs = {
  attachmentName: Scalars['String'];
  isOverride?: InputMaybe<Scalars['Boolean']>;
  machineId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type QueryCleaningReportsExportGetFileArgs = {
  requestId: Scalars['String'];
};


export type QueryCleaningTaskCleanedAreaByDayListArgs = {
  filter: InputFilterCleaningTaskCleanedAreaByDayList;
};


export type QueryCleaningTaskCleanedHrsByDayListArgs = {
  filter: InputFilterCleaningTaskCleanedHrsByDayList;
};


export type QueryCleaningTaskConsumablesByDayListArgs = {
  filter: InputFilterCleaningTaskConsumablesByDayList;
};


export type QueryCleaningTaskDistanceDrivenByDayListArgs = {
  filter: InputFilterCleaningTaskDistanceDrivenByDayList;
};


export type QueryCleaningTaskReportRobotRouteImageArgs = {
  finishedAt: Scalars['String'];
  machineId: Scalars['String'];
};


export type QueryCleaningTaskReportsArgs = {
  filter: InputFilterCleaningTaskReportsList;
  paginationOptions: InputPagingOptions;
  sortOptions?: InputMaybe<InputSortOptions>;
};


export type QueryFleetOperatingTimeArgs = {
  filter: InputFilterFleetOperatingTime;
};


export type QueryMachineArgs = {
  id: Scalars['ID'];
};


export type QueryMachineFilterArgs = {
  filter?: InputMaybe<InputMachineFilterFilter>;
  timezone: Scalars['String'];
};


export type QueryMachineIdsArgs = {
  filter?: InputMaybe<InputFilterMachineIdsList>;
};


export type QueryMachineVncStatusGetArgs = {
  input: MachineVncStatusGetInput;
};


export type QueryMachineVncStatusGetV2Args = {
  input: MachineVncStatusGetInputV2;
};


export type QueryMachinesArgs = {
  filter?: InputMaybe<InputFilterMachinesList>;
  paginationOptions?: InputMaybe<InputPagingOptions>;
  sortOptions?: InputMaybe<InputSortOptions>;
};


export type QueryMachinesExportFileArgs = {
  id: Scalars['String'];
};


export type QueryMachinesReportArgs = {
  filter: InputFilterMachinesReport;
  paginationOptions?: InputMaybe<InputPagingOptions>;
  period: InputPeriod;
  search?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<InputSortOptions>;
  timezone: Scalars['String'];
};


export type QueryMachinesReportExportGetArgs = {
  requestId: Scalars['String'];
};


export type QueryNotificationGetOperatorArgs = {
  id: Scalars['String'];
};


export type QueryNotificationListOperatorArgs = {
  paginationOptions: InputPagingOptions;
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<InputFilterNotificationsList>;
  lang?: InputMaybe<Scalars['String']>;
  paginationOptions: InputPagingOptions;
  sortOptions?: InputMaybe<InputSortOptions>;
};


export type QueryRobotCleaningRoutesListArgs = {
  filter: InputFilterRobotCleaningRoutesList;
};


export type QueryRobotConsumptionSummaryArgs = {
  filter: InputFilterCleaningConsumption;
};


export type QueryRobotConsumptionsListArgs = {
  filter: InputFilterCleaningConsumption;
};


export type QueryRobotDashboardKpIsArgs = {
  filter: InputFilterRobotDashboardKpIsGet;
  paginationOptions: InputPagingOptions;
};


export type QueryRobotDashboardTotalCleanedAreaArgs = {
  filter: InputFilterRobotDashboardTotalCleanedAreaGet;
  paginationOptions: InputPagingOptions;
};


export type QueryRobotDashboardTotalCleanedHrsArgs = {
  filter: InputFilterRobotDashboardTotalCleanedHrsGet;
  paginationOptions: InputPagingOptions;
};


export type QueryRobotTaskCompletionStatisticArgs = {
  filter: InputFilterRobotTaskCompletionStatisticGet;
};


export type QueryRoutineCleaningTaskReportArgs = {
  filter: InputFilterRoutineCleaningTaskReport;
};


export type QuerySingleRoutesAndRoutinesListArgs = {
  filter: InputFilterSingleRoutesAndRoutinesList;
  paginationOptions: InputPagingOptions;
  search?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<InputSortOptions>;
};


export type QuerySiteArgs = {
  id: Scalars['ID'];
};


export type QuerySiteAddressCoordinateArgs = {
  filter: InputFilterSiteAddressCoordinateGet;
};


export type QuerySiteAddressesSuggestionArgs = {
  filter: InputFilterSiteAddressesSuggestionList;
  paginationOptions: InputSiteAddressesSuggestionListPagingOptions;
};


export type QuerySitesArgs = {
  filter?: InputMaybe<InputFilterSitesList>;
  paginationOptions: InputPagingOptions;
  sortOptions?: InputMaybe<InputSortOptions>;
};


export type QueryTagsArgs = {
  filter?: InputMaybe<InputFilterTagsList>;
  paginationOptions: InputPagingOptions;
};


export type QueryTagsItemsArgs = {
  filter: InputFilterTagsItemsGet;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<InputFilterUsersList>;
  paginationOptions: InputPagingOptions;
};


export type QueryWorkIntervalsArgs = {
  filter: InputFilterWorkIntervalsList;
};

export enum RecurrenceInterval {
  EveryWeek = 'EVERY_WEEK'
}

export type Reminder = {
  __typename?: 'Reminder';
  description: Scalars['String'];
  id: Scalars['ID'];
  lastRemindAt?: Maybe<Scalars['AWSDateTime']>;
  localRepetitiveDate: Scalars['AWSDate'];
  localRepetitiveTime: Scalars['AWSTime'];
  machineId: Scalars['String'];
  nextRemindAt: Scalars['AWSDateTime'];
  repetition: RepetitionInterval;
  startAt: Scalars['AWSDateTime'];
  timezone: Scalars['String'];
  topic: Scalars['String'];
};

export enum RepetitionInterval {
  Every_3Months = 'EVERY_3_MONTHS',
  Every_6Months = 'EVERY_6_MONTHS',
  Monthly = 'MONTHLY',
  Once = 'ONCE',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum ReportType {
  EventReport = 'EVENT_REPORT',
  RobotReport = 'ROBOT_REPORT',
  TotalReport = 'TOTAL_REPORT'
}

export type ResponseAcceptTermsAndCondition = {
  __typename?: 'ResponseAcceptTermsAndCondition';
  data?: Maybe<Array<AcceptTermsAndCondition>>;
};

export type ResponseAttachmentDelete = {
  __typename?: 'ResponseAttachmentDelete';
  data: Scalars['Boolean'];
};

export type ResponseAttachmentDownloadLinkGet = {
  __typename?: 'ResponseAttachmentDownloadLinkGet';
  data?: Maybe<Scalars['String']>;
};

export type ResponseAttachmentNameCheck = {
  __typename?: 'ResponseAttachmentNameCheck';
  data?: Maybe<AttachmentNameCheck>;
};

export type ResponseAttachmentUploadLinkGet = {
  __typename?: 'ResponseAttachmentUploadLinkGet';
  data?: Maybe<AttachmentUploadLinkData>;
};

export type ResponseBoolean = {
  __typename?: 'ResponseBoolean';
  data: Scalars['Boolean'];
};

export type ResponseCheckAccessibleClassification = {
  __typename?: 'ResponseCheckAccessibleClassification';
  hasAccessToGCD?: Maybe<Scalars['Boolean']>;
  hasAccessToRobots?: Maybe<Scalars['Boolean']>;
};

export type ResponseCleaningReportSubscriptions = {
  __typename?: 'ResponseCleaningReportSubscriptions';
  data: Array<CleaningReportSubscription>;
};

export type ResponseCleaningReportsExportRequest = {
  __typename?: 'ResponseCleaningReportsExportRequest';
  data: CleaningReportsExportRequestOutput;
};

export type ResponseCleaningStatistic = {
  __typename?: 'ResponseCleaningStatistic';
  data?: Maybe<RobotDashboardKpIsData>;
};

export type ResponseCleaningTaskCleanedAreaByDayList = {
  __typename?: 'ResponseCleaningTaskCleanedAreaByDayList';
  data: CleaningTaskCleanedAreaByDayList;
};

export type ResponseCleaningTaskCleanedHrsByDayList = {
  __typename?: 'ResponseCleaningTaskCleanedHrsByDayList';
  data: CleaningTaskCleanedHrsByDayList;
};

export type ResponseCleaningTaskConsumablesByDayList = {
  __typename?: 'ResponseCleaningTaskConsumablesByDayList';
  data: CleaningTaskConsumablesByDayList;
};

export type ResponseCleaningTaskDistanceDrivenByDayList = {
  __typename?: 'ResponseCleaningTaskDistanceDrivenByDayList';
  data: CleaningTaskDistanceDrivenByDayList;
};

export type ResponseCleaningTaskReportsData = {
  __typename?: 'ResponseCleaningTaskReportsData';
  data: Array<CleaningTaskReport>;
};

export type ResponseCleaningTaskReportsList = {
  __typename?: 'ResponseCleaningTaskReportsList';
  data: Array<CleaningTaskReport>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseFleetOperatingTime = {
  __typename?: 'ResponseFleetOperatingTime';
  data: FleetOperatingTime;
};

export type ResponseGetCleaningReportsExportFile = {
  __typename?: 'ResponseGetCleaningReportsExportFile';
  data: GetExportFileOutput;
};

export type ResponseGetMachinesReportExport = {
  __typename?: 'ResponseGetMachinesReportExport';
  data?: Maybe<GetExportOutput>;
};

export type ResponseLatestCleaningTaskReport = {
  __typename?: 'ResponseLatestCleaningTaskReport';
  data?: Maybe<CleaningTaskReport>;
};

export type ResponseLatestRoutine = {
  __typename?: 'ResponseLatestRoutine';
  data?: Maybe<CleaningRoutine>;
};

export type ResponseMachineAttachmentsList = {
  __typename?: 'ResponseMachineAttachmentsList';
  data: Array<Attachment>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseMachineFilter = {
  __typename?: 'ResponseMachineFilter';
  data: MachineFilter;
};

export type ResponseMachineGet = {
  __typename?: 'ResponseMachineGet';
  data?: Maybe<Machine>;
};

export type ResponseMachineIdsList = {
  __typename?: 'ResponseMachineIdsList';
  data: Array<Scalars['ID']>;
};

export type ResponseMachineNoteCreate = {
  __typename?: 'ResponseMachineNoteCreate';
  data: MachineNote;
};

export type ResponseMachineNoteUpdate = {
  __typename?: 'ResponseMachineNoteUpdate';
  data: MachineNote;
};

export type ResponseMachineNotesList = {
  __typename?: 'ResponseMachineNotesList';
  data: Array<MachineNote>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseMachineTypesList = {
  __typename?: 'ResponseMachineTypesList';
  data: Array<MachineTypeByName>;
};

export type ResponseMachineUpdate = {
  __typename?: 'ResponseMachineUpdate';
  data: Machine;
};

export type ResponseMachineVariantsList = {
  __typename?: 'ResponseMachineVariantsList';
  data: Array<MachineVariant>;
};

export type ResponseMachineVncRequest = {
  __typename?: 'ResponseMachineVncRequest';
  data: MachineVncRequestResponse;
};

export type ResponseMachineVncRequestV2 = {
  __typename?: 'ResponseMachineVncRequestV2';
  data: MachineVncRequestResponseV2;
};

export type ResponseMachineVncStatusGet = {
  __typename?: 'ResponseMachineVncStatusGet';
  data: MachineVncStatusGetResponse;
};

export type ResponseMachineVncStatusGetV2 = {
  __typename?: 'ResponseMachineVncStatusGetV2';
  data: MachineVncStatusGetResponse;
};

export type ResponseMachinesAvailableToBeAssigned = {
  __typename?: 'ResponseMachinesAvailableToBeAssigned';
  data: Array<Machine>;
};

export type ResponseMachinesExportFile = {
  __typename?: 'ResponseMachinesExportFile';
  data: MachinesExportFile;
};

export type ResponseMachinesList = {
  __typename?: 'ResponseMachinesList';
  data: Array<Machine>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseMachinesListExportRequest = {
  __typename?: 'ResponseMachinesListExportRequest';
  data: MachinesListExportRequestOutput;
};

export type ResponseMachinesReportExportRequest = {
  __typename?: 'ResponseMachinesReportExportRequest';
  data: MachinesReportExport;
};

export type ResponseMachinesReportList = {
  __typename?: 'ResponseMachinesReportList';
  data: Array<MachinesReport>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseMachinesReportSubscriptions = {
  __typename?: 'ResponseMachinesReportSubscriptions';
  data: Array<MachinesReportSubscription>;
};

export type ResponseMetadata = {
  __typename?: 'ResponseMetadata';
  paginationToken?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type ResponseNotificationDeleteOperator = {
  __typename?: 'ResponseNotificationDeleteOperator';
  data?: Maybe<Scalars['Boolean']>;
};

export type ResponseNotificationGetOperator = {
  __typename?: 'ResponseNotificationGetOperator';
  data?: Maybe<NotificationOperator>;
};

export type ResponseNotificationListOperator = {
  __typename?: 'ResponseNotificationListOperator';
  data: Array<NotificationOperator>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseNotificationUpdateNotifyStatus = {
  __typename?: 'ResponseNotificationUpdateNotifyStatus';
  data?: Maybe<NotificationUser>;
};

export type ResponseNotificationUpsertOperator = {
  __typename?: 'ResponseNotificationUpsertOperator';
  data?: Maybe<NotificationOperator>;
};

export type ResponseNotificationUser = {
  __typename?: 'ResponseNotificationUser';
  data?: Maybe<NotificationUser>;
};

export type ResponseNotificationsList = {
  __typename?: 'ResponseNotificationsList';
  data: Array<Notification>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseReminderCreate = {
  __typename?: 'ResponseReminderCreate';
  data?: Maybe<Reminder>;
};

export type ResponseRemindersList = {
  __typename?: 'ResponseRemindersList';
  data: Array<Reminder>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseRobotCleaningConsumptionSummary = {
  __typename?: 'ResponseRobotCleaningConsumptionSummary';
  data?: Maybe<RobotCleaningConsumption>;
};

export type ResponseRobotCleaningConsumptionsList = {
  __typename?: 'ResponseRobotCleaningConsumptionsList';
  data?: Maybe<Array<Maybe<RobotCleaningConsumptionList>>>;
};

export type ResponseRobotCleaningRoutesList = {
  __typename?: 'ResponseRobotCleaningRoutesList';
  data: RobotCleaningRoutesListData;
};

export type ResponseRobotDashboardKpIsGet = {
  __typename?: 'ResponseRobotDashboardKPIsGet';
  data: RobotDashboardKpIsData;
};

export type ResponseRobotDashboardTotalCleanedAreaGet = {
  __typename?: 'ResponseRobotDashboardTotalCleanedAreaGet';
  data: Array<RobotDashboardTotalCleanedAreaData>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseRobotDashboardTotalCleanedHrsGet = {
  __typename?: 'ResponseRobotDashboardTotalCleanedHrsGet';
  data: Array<RobotDashboardTotalCleanedHrsData>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseRobotDetailsReportsExportRequest = {
  __typename?: 'ResponseRobotDetailsReportsExportRequest';
  data: RobotDetailsReportsExportRequestOutput;
};

export type ResponseRobotTaskCompletionStatisticGet = {
  __typename?: 'ResponseRobotTaskCompletionStatisticGet';
  data: RobotTaskCompletionStatisticData;
};

export type ResponseRoutineCleaningTaskReport = {
  __typename?: 'ResponseRoutineCleaningTaskReport';
  data: RoutineCleaningTaskReportData;
};

export type ResponseRoutineData = {
  __typename?: 'ResponseRoutineData';
  data: Routine;
};

export type ResponseRoutineReportExportRequest = {
  __typename?: 'ResponseRoutineReportExportRequest';
  data: RoutineReportExportRequestOutput;
};

export type ResponseSingleRoutesAndRoutinesList = {
  __typename?: 'ResponseSingleRoutesAndRoutinesList';
  data?: Maybe<Array<Maybe<SingleRoutesAndRoutineData>>>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseSiteAddressCoordinateGet = {
  __typename?: 'ResponseSiteAddressCoordinateGet';
  data?: Maybe<Coordinate>;
};

export type ResponseSiteAddressesSuggestionList = {
  __typename?: 'ResponseSiteAddressesSuggestionList';
  data: Array<Scalars['String']>;
};

export type ResponseSiteAssignMachine = {
  __typename?: 'ResponseSiteAssignMachine';
  data: Array<Maybe<MachineId>>;
};

export type ResponseSiteAssignManagers = {
  __typename?: 'ResponseSiteAssignManagers';
  data?: Maybe<Array<Manager>>;
};

export type ResponseSiteCreate = {
  __typename?: 'ResponseSiteCreate';
  data: Site;
};

export type ResponseSiteGet = {
  __typename?: 'ResponseSiteGet';
  data: Site;
};

export type ResponseSiteTagAdd = {
  __typename?: 'ResponseSiteTagAdd';
  data: SiteTag;
};

export type ResponseSiteTagsOverride = {
  __typename?: 'ResponseSiteTagsOverride';
  data?: Maybe<SiteTagsOverride>;
};

export type ResponseSiteUnassignMachine = {
  __typename?: 'ResponseSiteUnassignMachine';
  data: MachineId;
};

export type ResponseSiteUnassignManager = {
  __typename?: 'ResponseSiteUnassignManager';
  data: Manager;
};

export type ResponseSiteUpdate = {
  __typename?: 'ResponseSiteUpdate';
  data: Site;
};

export type ResponseSitesList = {
  __typename?: 'ResponseSitesList';
  data: Array<Site>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseString = {
  __typename?: 'ResponseString';
  data: Scalars['String'];
};

export type ResponseTagsItemsGet = {
  __typename?: 'ResponseTagsItemsGet';
  data: Array<Scalars['String']>;
};

export type ResponseTagsList = {
  __typename?: 'ResponseTagsList';
  data: Array<Tag>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseUserInvite = {
  __typename?: 'ResponseUserInvite';
  data?: Maybe<Scalars['Boolean']>;
};

export type ResponseUserUnauthorize = {
  __typename?: 'ResponseUserUnauthorize';
  data: User;
};

export type ResponseUserUpdate = {
  __typename?: 'ResponseUserUpdate';
  data: User;
};

export type ResponseUsersList = {
  __typename?: 'ResponseUsersList';
  data: Array<User>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ResponseWorkIntervalCreate = {
  __typename?: 'ResponseWorkIntervalCreate';
  data: WorkInterval;
};

export type ResponseWorkIntervalUpdate = {
  __typename?: 'ResponseWorkIntervalUpdate';
  data: WorkInterval;
};

export type ResponseWorkIntervalsList = {
  __typename?: 'ResponseWorkIntervalsList';
  data: Array<WorkInterval>;
};

export type RobotCleaningConsumption = {
  __typename?: 'RobotCleaningConsumption';
  battery: Scalars['Float'];
  detergents: Scalars['Float'];
  water: Scalars['Float'];
};

export type RobotCleaningConsumptionList = {
  __typename?: 'RobotCleaningConsumptionList';
  consumptions: RobotCleaningConsumption;
  finishedAt: Scalars['String'];
};

export type RobotCleaningRoutesListData = {
  __typename?: 'RobotCleaningRoutesListData';
  unavailableRoute?: Maybe<Array<Maybe<RobotCleaningRoutesObject>>>;
  validRoute?: Maybe<Array<Maybe<RobotCleaningRoutesObject>>>;
};

export type RobotCleaningRoutesObject = {
  __typename?: 'RobotCleaningRoutesObject';
  recentFinishedAt: Scalars['AWSDateTime'];
  routeName: Scalars['String'];
};

export type RobotDashboardKpIsData = {
  __typename?: 'RobotDashboardKPIsData';
  distanceDriven: Scalars['Float'];
  taskCoverage: Scalars['Float'];
  tasksCompleted: Scalars['Float'];
  totalCleanedArea: Scalars['Int'];
  totalCleaningHrs: Scalars['Float'];
};

export type RobotDashboardTotalCleanedAreaData = {
  __typename?: 'RobotDashboardTotalCleanedAreaData';
  cleanedArea: Scalars['Int'];
  machineId: Scalars['String'];
  machineName: Scalars['String'];
};

export type RobotDashboardTotalCleanedHrsData = {
  __typename?: 'RobotDashboardTotalCleanedHrsData';
  cleanedHrs: Scalars['Float'];
  machineId: Scalars['String'];
  machineName: Scalars['String'];
};

export type RobotDetailsReportsExportRequestOutput = {
  __typename?: 'RobotDetailsReportsExportRequestOutput';
  requestId: Scalars['String'];
};

export enum RobotStatus {
  Autonomous = 'Autonomous',
  Charging = 'Charging',
  Docking = 'Docking',
  Exploration = 'Exploration',
  Idle = 'Idle',
  ManualCleaning = 'ManualCleaning'
}

export type RobotTaskCompletionStatisticData = {
  __typename?: 'RobotTaskCompletionStatisticData';
  completed: Scalars['Int'];
  interrupted: Scalars['Int'];
  stopped: Scalars['Int'];
};

export enum Role {
  CustomerAdmin = 'CUSTOMER_ADMIN',
  CustomerOperator = 'CUSTOMER_OPERATOR',
  CustomerStrategicManager = 'CUSTOMER_STRATEGIC_MANAGER'
}

export type Routine = {
  __typename?: 'Routine';
  currentRepeat?: Maybe<Scalars['Int']>;
  executionId?: Maybe<Scalars['String']>;
  numberOfRepeats?: Maybe<Scalars['Int']>;
  routineName?: Maybe<Scalars['String']>;
};

export type RoutineCleaningTaskReportData = {
  __typename?: 'RoutineCleaningTaskReportData';
  cleaningTaskReports: ResponseCleaningTaskReportsData;
  robotConsumptionSummary: ResponseRobotCleaningConsumptionSummary;
  robotDashboardKPIs: ResponseRobotDashboardKpIsGet;
  routine: ResponseRoutineData;
};

export enum RoutineCompletion {
  Completed = 'COMPLETED',
  Interrupted = 'INTERRUPTED',
  Stopped = 'STOPPED'
}

export type RoutineReportExportRequestOutput = {
  __typename?: 'RoutineReportExportRequestOutput';
  requestId: Scalars['String'];
};

export enum RoutineStatus {
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS',
  Started = 'STARTED'
}

export type Service = {
  __typename?: 'Service';
  partNumber: Scalars['String'];
  servicePackage: ServicePackage;
};

export enum ServicePackage {
  FullService = 'FULL_SERVICE',
  FullServiceWithBatteries = 'FULL_SERVICE_WITH_BATTERIES',
  FullServiceWithConsumables = 'FULL_SERVICE_WITH_CONSUMABLES',
  FullServiceWithConsumablesAndBatteries = 'FULL_SERVICE_WITH_CONSUMABLES_AND_BATTERIES',
  Inspect = 'INSPECT',
  InspectWithBatteries = 'INSPECT_WITH_BATTERIES',
  InspectWithConsumables = 'INSPECT_WITH_CONSUMABLES',
  InspectWithConsumablesAndBatteries = 'INSPECT_WITH_CONSUMABLES_AND_BATTERIES',
  Maintain = 'MAINTAIN',
  MaintainWithBatteries = 'MAINTAIN_WITH_BATTERIES',
  MaintainWithConsumables = 'MAINTAIN_WITH_CONSUMABLES',
  MaintainWithConsumablesAndBatteries = 'MAINTAIN_WITH_CONSUMABLES_AND_BATTERIES',
  NoServiceContract = 'NO_SERVICE_CONTRACT'
}

export enum Severity {
  Critical = 'Critical',
  Major = 'Major',
  Minor = 'Minor'
}

export enum SingleRouteAndRoutineType {
  Routine = 'ROUTINE',
  SingleRoute = 'SINGLE_ROUTE'
}

export type SingleRoutesAndRoutineData = {
  __typename?: 'SingleRoutesAndRoutineData';
  CTRId?: Maybe<Scalars['String']>;
  actualOperatingTimeInSeconds?: Maybe<Scalars['Int']>;
  battery?: Maybe<Scalars['Float']>;
  cleanedSquareMeter?: Maybe<Scalars['Int']>;
  customerId: Scalars['String'];
  detergents?: Maybe<Scalars['Float']>;
  executionId?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['AWSDateTime']>;
  machine?: Maybe<Machine>;
  machineId?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  pathCoverage?: Maybe<Scalars['Float']>;
  routeName?: Maybe<Scalars['String']>;
  routineMaps?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  routineName?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['AWSDateTime']>;
  taskCompletion?: Maybe<TaskCompletion>;
  type?: Maybe<SingleRouteAndRoutineType>;
  water?: Maybe<Scalars['Float']>;
};

export type Site = {
  __typename?: 'Site';
  cleaningStatistic: ResponseCleaningStatistic;
  customerId: Scalars['String'];
  geofence?: Maybe<SiteGeofence>;
  id: Scalars['ID'];
  location?: Maybe<SiteLocation>;
  machines: ResponseMachinesList;
  managerIds?: Maybe<Array<Scalars['String']>>;
  managers?: Maybe<Array<User>>;
  name: Scalars['String'];
  numberMachinesAssigned: Scalars['Int'];
  operatingTimeForPeriod?: Maybe<SiteOperatingTimePeriod>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type SiteCleaningStatisticArgs = {
  filter: InputFilterSiteCleaningStatistic;
};


export type SiteMachinesArgs = {
  filter?: InputMaybe<InputFilterSiteMachinesList>;
  paginationOptions: InputPagingOptions;
};


export type SiteOperatingTimeForPeriodArgs = {
  period: InputPeriod;
};

export type SiteFilter = {
  __typename?: 'SiteFilter';
  name: Scalars['String'];
  siteId: Scalars['ID'];
};

export type SiteGeofence = {
  __typename?: 'SiteGeofence';
  centerPoint: Coordinate;
  id: Scalars['ID'];
  radius: Scalars['Float'];
};

export type SiteLocation = {
  __typename?: 'SiteLocation';
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type SiteOperatingTimePeriod = {
  __typename?: 'SiteOperatingTimePeriod';
  actualTotalOperatingTimeMs: Scalars['Long'];
  endAt: Scalars['AWSDateTime'];
  plannedTotalOperatingTimeMs: Scalars['Long'];
  startAt: Scalars['AWSDateTime'];
};

export type SiteTag = {
  __typename?: 'SiteTag';
  customerId: Scalars['String'];
  siteId: Scalars['String'];
  tag: Scalars['String'];
};

export type SiteTagsOverride = {
  __typename?: 'SiteTagsOverride';
  customerId: Scalars['String'];
  siteId: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export enum SortOrders {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type State = {
  __typename?: 'State';
  machineId: Scalars['String'];
  receivedAt?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['String']>;
  stateName: Scalars['String'];
  stateValue: Scalars['String'];
};

export enum Status {
  Active = 'active',
  Invited = 'invited',
  Unauthorized = 'unauthorized'
}

export type Subscription = {
  __typename?: 'Subscription';
  onMachineUpdate?: Maybe<MachineUpdate>;
};


export type SubscriptionOnMachineUpdateArgs = {
  customerId: Scalars['String'];
  machineId: Scalars['ID'];
};

export enum SupportedLanguage {
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  En = 'EN',
  Fr = 'FR',
  It = 'IT',
  Nb = 'NB',
  Nl = 'NL',
  Pl = 'PL',
  Sv = 'SV'
}

export type Tag = {
  __typename?: 'Tag';
  customerId: Scalars['String'];
  tag: Scalars['String'];
};

export enum TaskCompletion {
  Completed = 'COMPLETED',
  Interrupted = 'INTERRUPTED',
  Skipped = 'SKIPPED',
  Stopped = 'STOPPED'
}

export type TimeSpan = {
  durationMs: Scalars['Int'];
  startHoursUtc: Scalars['Int'];
  startMinutesUtc: Scalars['Int'];
};

export enum Type {
  Alert = 'Alert',
  Info = 'Info'
}

export type User = {
  __typename?: 'User';
  createdAt: Scalars['AWSDateTime'];
  customerId: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  role: Role;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']>;
};

export enum UserRole {
  CustomerAdmin = 'CUSTOMER_ADMIN',
  CustomerStrategicManager = 'CUSTOMER_STRATEGIC_MANAGER'
}

export type WorkInterval = {
  __typename?: 'WorkInterval';
  createdAt: Scalars['AWSDateTime'];
  customerId: Scalars['String'];
  durationMs: Scalars['Int'];
  id: Scalars['ID'];
  machine: Machine;
  recurring: RecurrenceInterval;
  startHoursUtc: Scalars['Int'];
  startMinutesUtc: Scalars['Int'];
  weekdaysUtc: Array<Scalars['Int']>;
};
