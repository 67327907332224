import { i18n as i18next } from 'i18next';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';
import { Flex } from 'antd';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import {
  SingleRouteAndRoutineType,
  SingleRoutesAndRoutineData,
  TaskCompletion,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { BarProgress } from 'lib/components/Progress/BarProgress/BarProgress';
import { StatusPill } from 'lib/components/StatusPill/StatusPill';
import { LoadingIndicator } from 'lib/components/LoadingIndicator/LoadingIndicator';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';

export const getCleaningReportListRobotColumns = ({
  t,
  i18n,
  handleClickMachineName,
  handleClickSiteName,
  handleClickDownloadRoutine,
  currentExportingId,
  handleOpenRouteImage,
  isUSusers,
  handleOpenRoutineDetails,
  handleClickDownloadRoute,
  currentExportingRouteId,
}: {
  t: TFunction;
  i18n: i18next;
  handleClickMachineName: (ctr: SingleRoutesAndRoutineData) => void;
  handleClickSiteName: (ctr: SingleRoutesAndRoutineData) => void;
  handleClickDownloadRoutine: (ctr: SingleRoutesAndRoutineData) => void;
  currentExportingId: Optional<string>;
  handleOpenRouteImage: (ctr: SingleRoutesAndRoutineData) => void;
  isUSusers: boolean;
  handleOpenRoutineDetails: (ctr: SingleRoutesAndRoutineData) => void;
  handleClickDownloadRoute: (ctr: SingleRoutesAndRoutineData) => void;
  currentExportingRouteId: Optional<string>;
}): ColumnsType<SingleRoutesAndRoutineData> => {
  const columns: ColumnsType<SingleRoutesAndRoutineData> = [
    {
      dataIndex: 'type',
      width: 54,
      key: 'type',
      className: 'routine-list__routine-icon-column',
      render: (_, singleRoutesAndRoutineData): JSX.Element =>
        singleRoutesAndRoutineData.type === SingleRouteAndRoutineType.Routine ? (
          <TextButton
            className="routine-list__routine-icon"
            onClick={(): void => {
              handleOpenRoutineDetails(singleRoutesAndRoutineData);
            }}
          >
            <SvgIcon name="routine" className="routine-icon" />
          </TextButton>
        ) : (
          <SvgIcon name="route" className="routine-icon" />
        ),
      fixed: 'left',
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
    {
      title: t('cleaningReportList.robot.table.columns.date'),
      dataIndex: 'startedAt',
      width: 185,
      key: 'startedAt',
      render: (startedAt): JSX.Element => (
        <>
          {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.DATE)}
          {', '}
          {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.WEEKDAY)}
        </>
      ),
      fixed: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'imageRoute',
      width: 54,
      key: 'imageRoute',
      className: 'routine-list__image-icon-column',
      render: (_, cleaningTaskReport): JSX.Element => (
        <Flex onClick={(): void => handleOpenRouteImage(cleaningTaskReport)}>
          <SvgIcon
            name={cleaningTaskReport.type === SingleRouteAndRoutineType.Routine ? 'imageMulti' : 'image'}
            className="image-icon"
          />
        </Flex>
      ),
      fixed: 'left',
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
    {
      width: 54,
      key: 'export',
      className: 'routine-list__export-icon-column',
      render: (_id: string, cleaningTaskReport): JSX.Element =>
        cleaningTaskReport.type === SingleRouteAndRoutineType.Routine ? (
          <div
            className="table__link--no-styling"
            onClick={(): void => {
              handleClickDownloadRoutine(cleaningTaskReport);
            }}
          >
            {currentExportingId === cleaningTaskReport.executionId ? (
              <LoadingIndicator size="small" />
            ) : (
              <SvgIcon name="exportReport" className="routine-list__table_export-icon" />
            )}
          </div>
        ) : (
          <div
            className="table__link--no-styling"
            onClick={(): void => {
              handleClickDownloadRoute(cleaningTaskReport);
            }}
          >
            {currentExportingRouteId === cleaningTaskReport.CTRId ? (
              <LoadingIndicator size="small" />
            ) : (
              <SvgIcon name="exportReport" className="routine-list__table_export-icon" />
            )}
          </div>
        ),
      fixed: 'left',
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
    {
      title: t('cleaningReportList.robot.table.columns.machineName'),
      dataIndex: ['machineName'],
      width: 220,
      key: 'machineName',
      render: (machineName: string, cleaningTaskReport): JSX.Element => (
        <div className="table__link--no-styling" onClick={(): void => handleClickMachineName(cleaningTaskReport)}>
          {machineName}
        </div>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.assignedSite'),
      dataIndex: ['machine', 'site', 'name'],
      width: 105,
      key: 'site',
      render: (_siteName: string, cleaningTaskReport): JSX.Element => {
        if (!cleaningTaskReport.machine?.site?.id) {
          return <>{t('cleaningReportList.unassignedLabel')}</>;
        }

        return (
          <div className="table__link--no-styling" onClick={(): void => handleClickSiteName(cleaningTaskReport)}>
            {cleaningTaskReport.siteName}
          </div>
        );
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.routine'),
      dataIndex: ['routineName'],
      width: 180,
      key: 'routine',
      render: routineName => `${routineName || t('common.NA')}`,
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.routeName'),
      dataIndex: 'routeName',
      width: 180,
      key: 'routeName',
      render: routeName => `${routeName || t('common.NA')}`,
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.pathCoverage'),
      dataIndex: 'pathCoverage',
      width: 120,
      key: 'pathCoverage',
      render: (pathCoverage): JSX.Element => (
        <BarProgress
          percent={Math.round((pathCoverage || 0) * 100)}
          format={(percent?: number | undefined): ReactNode => {
            if (percent === 100) return `${percent}%`;
            return `${percent}%`;
          }}
        />
      ),
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
    },

    {
      title: t('cleaningReportList.robot.table.columns.taskCompletion'),
      className: 'routine-list-robot__task-column',
      dataIndex: 'taskCompletion',
      width: 122,
      key: 'taskCompletion',
      render: (taskCompletion): JSX.Element => {
        let color = 'gray';
        switch (taskCompletion) {
          case TaskCompletion.Completed:
            color = 'green';
            break;
          case TaskCompletion.Interrupted:
            color = 'yellow';
            break;
          case TaskCompletion.Stopped:
            color = 'red';
            break;
          default:
            color = 'gray';
            break;
        }
        return (
          <StatusPill className="routine-list__status-pill" color={color}>
            {`${
              taskCompletion
                ? t(`machineDetails.taskCompletionHistory.labels.${taskCompletion.toLowerCase()}`)
                : t('common.NA')
            }`}
          </StatusPill>
        );
      },
      sorter: true,
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.cleanedSquareMeter')}</Flex>,
      dataIndex: 'cleanedSquareMeter',
      width: 130,
      key: 'cleanedSquareMeter',
      render: (cleanedSquareMeter): string =>
        isUSusers
          ? t('cleaningReportList.robot.table.values.cleanedSquareFeetValue', {
              cleanedSquareMeter: ConvertUnitUtils.covertSquareMetersToSquareFeet(cleanedSquareMeter || 0),
            })
          : t('cleaningReportList.robot.table.values.cleanedSquareMeterValue', {
              cleanedSquareMeter: Numbers.formatNumberDecimalRounded(cleanedSquareMeter || 0, 1),
            }),
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },

    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.startTime')}</Flex>,
      dataIndex: 'startedAt',
      width: 123,
      key: 'startedAt',
      render: (startedAt): JSX.Element => (
        <>{DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND)}</>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.stopTime')}</Flex>,
      dataIndex: 'finishedAt',
      width: 123,
      key: 'finishedAt',
      render: (finishedAt): JSX.Element => (
        <>{DateTime.formatDateByLocale(i18n.language, finishedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND)}</>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.duration')}</Flex>,
      dataIndex: 'actualOperatingTimeInSeconds',
      width: 123,
      key: 'actualOperatingTimeInSeconds',
      render: actualOperatingTimeInSeconds =>
        DateTime.formatDurationByMilliseconds({
          ms: actualOperatingTimeInSeconds * 1000,
        }),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },

    {
      title: t('cleaningReportList.robot.table.columns.battery'),
      dataIndex: 'battery',
      width: 180,
      key: 'battery',
      render: (battery): string =>
        t('cleaningReportList.robot.table.values.batteryValue', {
          battery: Numbers.formatNumberDecimalRounded(battery || 0, 1),
        }),
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },

    {
      title: t('cleaningReportList.robot.table.columns.water'),
      dataIndex: 'water',
      width: 180,
      key: 'water',
      render: (water): string =>
        isUSusers
          ? t('cleaningReportList.robot.table.values.waterUSValue', {
              water: ConvertUnitUtils.convertLitersToGallons(water || 0),
            })
          : t('cleaningReportList.robot.table.values.waterValue', {
              water: Numbers.formatNumberDecimalRounded(water || 0, 1),
            }),
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },

    {
      title: t('cleaningReportList.robot.table.columns.detergents'),
      dataIndex: 'detergents',
      width: 200,
      key: 'detergents',
      render: (detergents): string =>
        isUSusers
          ? t('cleaningReportList.robot.table.values.detergentsUSValue', {
              detergents: ConvertUnitUtils.convertLiterToFluidOunces(detergents || 0),
            })
          : t('cleaningReportList.robot.table.values.detergentsValue', {
              detergents: Numbers.formatNumberDecimalRounded(detergents || 0, 1),
            }),
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  return columns;
};
