import { ConfigurationSet } from '../app/cross-cutting-concerns/feature-flags/interfaces/FeatureFlag.types';

export enum Feature {
  MACHINE_LIST_MAP = 'MACHINE_LIST_MAP',
  MACHINE_NOTIFICATIONS = 'MACHINE_NOTIFICATIONS',
  MACHINE_DETAILS_OPERATING_HOURS_CHART = 'MACHINE_DETAILS_OPERATING_HOURS_CHART',
  SITE_DETAILS_MAP = 'SITE_DETAILS_MAP',
  NOTIFICATIONS = 'NOTIFICATIONS',
  USER_LIST = 'USER_LIST',
  LIST_MANAGERS = 'LIST_MANAGERS',
  USER_LIST_FILTERS_AND_SORTING = 'USER_LIST_FILTERS_AND_SORTING',
  NOTIFICATION_FILTERS_AND_SORTING = 'NOTIFICATION_FILTERS_AND_SORTING',
  NOTIFICATION_SEVERITY_AND_TYPE_FILTERS = 'NOTIFICATION_SEVERITY_AND_TYPE_FILTERS',
  SERVICE = 'SERVICE',
  CUSTOMER_INVITE = 'CUSTOMER_INVITE',
  CUSTOMER_UNAUTHORIZE = 'CUSTOMER_UNAUTHORIZE',
  PRIORITY_NOTIFICATIONS = 'PRIORITY_NOTIFICATIONS',
  ROBOT_INTEGRATION = 'ROBOT_INTEGRATION',
  MACHINE_REMINDER_LIST = 'MACHINE_REMINDER_LIST',
  MACHINE_NOTE_LIST = 'MACHINE_NOTE_LIST',
  MACHINE_ATTACHMENTS = 'MACHINE_ATTACHMENTS',
  ROBOTICS_DETAILS_PAGE = 'ROBOTICS_DETAILS_PAGE',
  ROBOTICS_DASHBOARD_PAGE = 'ROBOTICS_DASHBOARD_PAGE',
  ROBOTICS_CV50 = 'ROBOTICS_CV50',
  BATTERY_DEEP_DISCHARGE = 'BATTERY_DEEP_DISCHARGE',
}

export type IConfigurationSetFeatureMap = Record<ConfigurationSet, Feature[]>;

export const ConfigurationSetFeatureMap: IConfigurationSetFeatureMap = {
  // Hidden features will never be shown
  [ConfigurationSet.HIDDEN]: [
    Feature.SERVICE,
    Feature.PRIORITY_NOTIFICATIONS,
    Feature.USER_LIST_FILTERS_AND_SORTING,
    Feature.CUSTOMER_UNAUTHORIZE,
    Feature.NOTIFICATION_SEVERITY_AND_TYPE_FILTERS,
  ],
  // Next EM version features will only be shown for users in the
  // `NEXT_EM_VERSION_FEATURE_ACCESS` group when the feature flag
  // `enableNextEMVersionFeatures` is disabled. They will be shown
  // to all users when the feature flag is enabled.
  [ConfigurationSet.NEXT_EM_VERSION_FEATURES]: [Feature.BATTERY_DEEP_DISCHARGE],
  // Robotics features will only be shown for users in the
  // `ROBOTICS_FEATURE_ACCESS` group when the feature flag
  // `enableRoboticsFeatures` is disabled. They will be shown
  // to all users when the feature flag is enabled.
  [ConfigurationSet.ROBOTICS_FEATURES]: [Feature.ROBOTICS_CV50],
  // Stable features will always be shown
  [ConfigurationSet.STABLE]: [
    Feature.MACHINE_LIST_MAP,
    Feature.MACHINE_NOTIFICATIONS,
    Feature.MACHINE_DETAILS_OPERATING_HOURS_CHART,
    Feature.SITE_DETAILS_MAP,
    Feature.NOTIFICATIONS,
    Feature.USER_LIST,
    Feature.LIST_MANAGERS,
    Feature.NOTIFICATION_FILTERS_AND_SORTING,
    Feature.CUSTOMER_INVITE,
    Feature.MACHINE_REMINDER_LIST,
    Feature.MACHINE_NOTE_LIST,
    Feature.MACHINE_ATTACHMENTS,
    Feature.ROBOT_INTEGRATION,
    Feature.ROBOTICS_DETAILS_PAGE,
    Feature.ROBOTICS_DASHBOARD_PAGE,
  ],
};

export enum AwsFeatureFlagUserGroup {
  NEXT_EM_VERSION_FEATURE_ACCESS = 'NEXT_EM_VERSION_FEATURE_ACCESS',
  ROBOTICS_FEATURE_ACCESS = 'ROBOTICS_FEATURE_ACCESS',
}

export type IFeatureFlagConfig = {
  [key in Feature]: boolean;
};

// Setting a `false` fallback for every feature here
// The actual feature configuration will be evaluated using the FeatureFlagService.
export const initialFeatureFlagState = Object.values(Feature).reduce<IFeatureFlagConfig>(
  (accumulator, feature) => ({
    ...accumulator,
    [feature]: false,
  }),
  {} as IFeatureFlagConfig
);
