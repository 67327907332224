import { ApolloQueryResult, gql } from '@apollo/client';
import { AxiosResponse } from 'axios/index';
import { inject, injectable } from 'tsyringe';
import {
  InputFilterCleaningTaskReportsList,
  InputFilterSingleRoutesAndRoutinesList,
  InputPagingOptions,
  InputSortOptions,
  QueryCleaningTaskReportRobotRouteImageArgs,
} from '../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  CleaningTaskReportListResponse,
  CleaningTaskReportRouteImageResponse,
  RobotEventListResponse,
  RoutineCleaningTaskReportResponse,
} from './interfaces/CleaningTaskReport.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import type { RestClient } from 'app/cross-cutting-concerns/communication/clients/RestClientFactory';

export interface CleaningTaskReportClientListOptions {
  filter?: InputFilterCleaningTaskReportsList;
  sortOptions?: InputSortOptions;
  paginationOptions: InputPagingOptions;
}

export type CleaningTaskReportClientGetRobotRouteImageUrlOptions = QueryCleaningTaskReportRobotRouteImageArgs;

export interface CleaningTaskReportClientGetRobotRouteImageDataOptions {
  robotRouteImageUrl: string;
}

export interface RobotEventClientListOptions {
  filter?: InputFilterSingleRoutesAndRoutinesList;
  sortOptions?: InputSortOptions;
  search: string;
  paginationOptions: InputPagingOptions;
}

export interface RoutineDetailsClientOptions {
  filter: {
    executionId: string;
  };
}

@injectable()
export class CleaningTaskReportClient {
  constructor(
    @inject('GraphQlClient') private graphQlClient: GraphQlClient,
    @inject('RestClient') private restClient: RestClient
  ) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: CleaningTaskReportClientListOptions): Promise<ApolloQueryResult<CleaningTaskReportListResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskReports(
          $filter: InputFilterCleaningTaskReportsList!
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions!
        ) {
          cleaningTaskReports(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            metadata {
              totalCount
              paginationToken
            }
            data {
              routineName
              id
              machine {
                materialNumber
                serialNumber
                id
                name
                type {
                  name
                }
                batteryDischargeStatus
                connectionStatus
                isIoTDevice
                site {
                  name
                  id
                }
              }
              actualOperatingTimeInSeconds
              startedAt
              finishedAt
              routeName
              pathCoverage
              cleanedSquareMeter
              taskCompletion
              repeatPathId
              machine {
                id
                name
                type {
                  name
                }
                site {
                  id
                  name
                }
                metadata {
                  inventoryNumber
                }
              }
              currentRepeat
              executionId
              totalRepeats
              routineName
              distance
              detergents
              water
              materialNumber
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public getRobotRouteImageUrl = async ({
    machineId,
    finishedAt,
  }: CleaningTaskReportClientGetRobotRouteImageUrlOptions): Promise<
    ApolloQueryResult<CleaningTaskReportRouteImageResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskReportRobotRouteImage($machineId: String!, $finishedAt: String!) {
          cleaningTaskReportRobotRouteImage(machineId: $machineId, finishedAt: $finishedAt) {
            data
          }
        }
      `,
      variables: {
        machineId,
        finishedAt,
      },
    });

  public getRobotRouteImageData = async ({
    robotRouteImageUrl,
  }: CleaningTaskReportClientGetRobotRouteImageDataOptions): Promise<AxiosResponse<Blob>> =>
    this.restClient(robotRouteImageUrl, { responseType: 'blob' });

  public listRobotEvents = async ({
    filter,
    sortOptions,
    search,
    paginationOptions,
  }: RobotEventClientListOptions): Promise<ApolloQueryResult<RobotEventListResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query SingleRoutesAndRoutinesList(
          $filter: InputFilterSingleRoutesAndRoutinesList!
          $sortOptions: InputSortOptions
          $search: String
          $paginationOptions: InputPagingOptions!
        ) {
          singleRoutesAndRoutinesList(
            filter: $filter
            sortOptions: $sortOptions
            search: $search
            paginationOptions: $paginationOptions
          ) {
            data {
              actualOperatingTimeInSeconds
              battery
              cleanedSquareMeter
              detergents
              executionId
              finishedAt
              machineId
              machineName
              materialNumber
              pathCoverage
              routeName
              routineMaps
              routineName
              serialNumber
              siteName
              startedAt
              taskCompletion
              type
              water
              CTRId
              machine {
                site {
                  id
                }
              }
            }
            metadata {
              paginationToken
              totalCount
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        search,
        paginationOptions,
      },
    });

  public getRoutineDetails = async ({
    filter,
  }: RoutineDetailsClientOptions): Promise<ApolloQueryResult<RoutineCleaningTaskReportResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query RoutineCleaningTaskReport($filter: InputFilterRoutineCleaningTaskReport!) {
          routineCleaningTaskReport(filter: $filter) {
            data {
              cleaningTaskReports {
                data {
                  actualOperatingTimeInSeconds
                  battery
                  classification
                  cleanedSquareMeter
                  currentRepeat
                  customerId
                  detergents
                  distance
                  executionId
                  finishedAt
                  id
                  machine {
                    id
                    name
                  }
                  machineName
                  mapId
                  materialNumber
                  pathCoverage
                  repeatPathId
                  routeName
                  routineName
                  serialNumber
                  startedAt
                  taskCompletion
                  totalRepeats
                  water
                }
              }
              robotConsumptionSummary {
                data {
                  battery
                  detergents
                  water
                }
              }
              robotDashboardKPIs {
                data {
                  distanceDriven
                  taskCoverage
                  tasksCompleted
                  totalCleanedArea
                  totalCleaningHrs
                }
              }
              routine {
                data {
                  currentRepeat
                  executionId
                  numberOfRepeats
                  routineName
                }
              }
            }
          }
        }
      `,
      variables: { filter },
    });
}
